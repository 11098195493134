<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General Settings</span>
      </template>
      <account />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>
      <change-password />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="KeyIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Pin</span>
      </template>
      <change-pin />
    </b-tab>
  </b-tabs>
</template>

<script>
import Account from './Account.vue'
import ChangePassword from './ChangePassword.vue'
import ChangePin from './ChangePin.vue'

export default {
  components: { Account, ChangePassword, ChangePin },

}
</script>

<style>

</style>
