<template>
  <b-card>
    <b-media
      no-body
      class="mb-1"
    >
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="accountForm.profile_picture"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="selectFile()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          accept=".jpg, .png"
          :hidden="true"
          plain
          @change="changeProfile($event)"
        />
        <!--/ upload button -->
      </b-media-body>
    </b-media>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="First Name"
          label-for="firstname"
        >
          <b-form-input
            id="firstname"
            v-model="accountForm.first_name"
            autofocus
            trim
            placeholder="First Name"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Last Name"
          label-for="lastname"
        >
          <b-form-input
            id="lastname"
            v-model="accountForm.last_name"
            autofocus
            trim
            placeholder="Last Name"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Username"
          label-for="username"
        >
          <b-form-input
            id="username"
            v-model="accountForm.username"
            autofocus
            trim
            placeholder="Username"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="accountForm.email"
            autofocus
            trim
            placeholder="Email"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Phone"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            v-model="accountForm.phone"
            autofocus
            trim
            placeholder="Phone"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Gender"
          label-for="gender"
        >
          <b-form-radio-group
            id="gender"
            v-model="accountForm.gender"
            :options="genderOptions"
            value-field="value"
            text-field="name"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mt-50"
      >
        <b-button
          v-if="!loader"
          variant="primary"
          class="mr-1"
          @click="addUser()"
        >
          Save Changes
        </b-button>
        <b-button
          v-if="loader"
          variant="primary"
          disabled
          class="mr-1"
        >
          <b-spinner
            small
            type="grow"
          />
          Loading...
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import store from '@/store'

export default {
  setup() {
    const genderOptions = [
      { name: 'Male', value: 'male' },
      { name: 'Female', value: 'fenale' },
    ]
    const accountForm = ref({
      first_name: '',
      last_name: '',
      gender: '',
      username: '',
      email: '',
      phone: '',
    })
    // eslint-disable-next-line camelcase
    const profile_picture = ref('')
    const loader = ref(false)
    return {
      accountForm,
      genderOptions,
      loader,
      profile_picture,
    }
  },
  mounted() {
    this.getUser()
  },
  methods: {
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.profile_picture = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.accountForm.profile_picture = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    getUser() {
      axios.get('get-user').then(res => {
        if (res.status === 200) {
          this.accountForm = res.data?.user
        }
      })
    },
    async addUser() {
      this.loader = true
      try {
        const response = await axios.post('settings/profile', {
          first_name: this.accountForm.first_name,
          last_name: this.accountForm.last_name,
          gender: this.accountForm.gender,
          username: this.accountForm.username,
          email: this.accountForm.email,
          phone: this.accountForm.phone,
        }).then(() => {
          const formData = new FormData()
          formData.append('profile_picture', this.profile_picture)
          axios.post('settings/profile-image', formData).then(res => {
            if (res.status === 200) {
              store.commit('login/SET_USER_INFO', res.data.data)
              this.$toasted.show('Updated Successfully', {
                position: 'bottom-center',
                duration: 3000,
              })
              this.getUser()
            }
          })
        })
        if (response.status === 200) {
          this.$toasted.show('Updated Successfully', {
            position: 'bottom-center',
            duration: 3000,
          })
          this.getUser()
        }
      } catch (e) {
        // console.log(e)
      } finally {
        this.loader = false
      }
    },
  },
}
</script>

<style>

</style>
